// Translated
// Migrated
<template lang="pug">
#side-menu.side-menu__wrap.pb-5(:class="classes")
  .blue-container.side-menu(
    :class="{ 'side-menu-collapsed': collapsed, 'side-menu-collapsible': collapsible, 'side-menu-overflow': overflow }"
  )
    .sidemenu-collapse(
      v-if="collapsible"
      @click="collapsed = !collapsed"
    )
      button.btn.btn-link.btn-white
        fa.text-white(:icon="collapsed ? 'chevron-down' : 'chevron-up'")
    .submenu-overlay(
      v-show="activeSubmenu"
      @click="setSubmenu(null)"
    )

    h5.side-menu-title.mt-4
      span(data-i18n="sideMenuTitle") {{ $t('sideMenuTitle') }}

    h5.side-menu-title(
      v-for="(toplink, i) in tripTypesSidebar.toplinks",
      :key="i"
    )
      span.side-menu-icon(
        :class="{ 'side-menu-icon-star': toplink.icon === 'star' }"
      )
        fa(:icon="icon(toplink.icon)")
      nuxt-link.d-inline(
        :to="toplink.url",
        @click="menuStore.closeSideMenuActive()"
      ) {{ toplink.name }}

    hr

    template(v-if="sideMenuActive")
      LazyAlgoliaSearchBar(
        :autofocus="false",
        :header="true"
        @search="menuStore.closeSideMenuActive()",
        @hide-search-bar="menuStore.closeSideMenuActive()"
      )

    ul.side-menu-section(
      v-for="(triptypesGroup, index) in tripTypesSidebar.content",
      :key="index",
      :class="{ 'with-border': Object.keys(triptypesGroup.categories).length === 1 }"
    )
      li(
        :class="activeSubmenu === triptypesGroup.name ? 'active' : 'non'"
      )
        span.side-menu-title.category-title(v-if="hasSubCategories(triptypesGroup)") {{ triptypesGroup.name }}
        template(v-else)
          a.side-menu-title.category-title(
            :href="triptypesGroup.categories[Object.keys(triptypesGroup.categories)[0]].category_links[0].url"
            @click.prevent="setSubmenu(triptypesGroup.name)"
          ) {{ triptypesGroup.name }}
          LazySubmenu.py-0.side-menu-subcategories(
            v-if="activeSubmenu === triptypesGroup.name",
            :title="triptypesGroup.name",
            :title-translation="triptypesGroup?.name_translationkey",
            :trips="triptypesGroup.categories[Object.keys(triptypesGroup.categories)[0]].trips",
            :category-links="triptypesGroup.categories[Object.keys(triptypesGroup.categories)[0]].category_links",
            @close="setSubmenu(null)"
          )
      li(v-if="hasSubCategories(triptypesGroup)")
        ul.side-menu-subcategories
          li(
            v-for="(category, idx) in triptypesGroup.categories",
            :key="idx",
            :class="activeSubmenu === category.name ? 'active' : 'non'"
          )
            a(
              :href="category.category_links[0].url"
              @click.prevent="setSubmenu(category.name)"
            ) {{ category.name }}
            LazySubmenu(
              v-if="activeSubmenu === category.name",
              :title="category.name",
              :trips="category.trips",
              :category-links="category.category_links",
              @close="setSubmenu(null)"
            )

    .hamburgerMenuWrapper
      ul.side-menu-section(
        v-for="(navItem, index) in topNavbar"
        :key="index"
      )
        li(
          v-if="navItem.mobileview && sideMenuActive",
          :class="activeHamburgerMenu === navItem.label ? 'active' : 'non'",
          @click="activeHamburgerMenu = navItem.label"
        )
          .d-flex.justify-content-between.side-menu-title
            a(v-if="navItem.submenu && navItem.submenu.length") {{ navItem.label }}
            nuxt-link(
              v-else,
              :to="navItem.link",
              @click="menuStore.closeSideMenuActive()"
            ) {{ navItem.label }}
            button.btn.btn-link.submenu-close.text-white.py-0(
              v-if="activeHamburgerMenu === navItem.label"
              @click.stop="activeHamburgerMenu = null"
            )
              fa(
                icon="times"
                width="18"
              )
          ul.bg-white.w-100.py-3(
            v-if="navItem.submenu && activeHamburgerMenu === navItem.label"
          )
            li.py-1(
              v-for="(item, i) in navItem.submenu"
              :key="i"
            )
              .dropdown-divider(v-if="item.label === 'linebreak'")
              a.dropdown-item.px-3.text-dark(
                v-else-if="item.absoluteLink",
                :href="item.link",
                target="_blank"
              ) {{ item.label }}
              nuxt-link.px-3.text-dark(
                v-else,
                :to="item.link",
                @click="menuStore.closeSideMenuActive()"
              ) {{ item.label }}
</template>

<script>
import { mapState } from 'pinia'
import { faList, faGlobe } from '@fortawesome/pro-solid-svg-icons'
import { faCalendar } from '@fortawesome/pro-duotone-svg-icons'

const icons = {
  faList,
  faGlobe,
}

export default defineNuxtComponent({
  setup () {
    const menuStore = useMenuStore()

    return {
      menuStore,
    }
  },

  name: 'Sidebar',

  props: {
    collapsible: {
      type: Boolean,
      default: false,
    },

    preventLayoutingClasses: {
      type: Boolean,
      default: false,
    },
  },

  data () {
    return {
      activeSubmenu: null,
      activeHamburgerMenu: null,
      collapsed: this.collapsible,
      overflow: false,
      timer: null,
    }
  },

  computed: {
    ...mapState(useRootStore, {
      topNavbar: 'topNavbar',
      tripTypesSidebar: 'tripTypesSidebar',
    }),

    ...mapState(useMenuStore, {
      sideMenuActive: 'sideMenuActive',
    }),

    classes () {
      let c = []
      if (!this.preventLayoutingClasses) {
        c = ['col-xl-3', 'col-lg-3', 'd-none', 'd-lg-block', 'mr-auto', 'float-left', 'px-0']
      }
      if (this.sideMenuActive) {
        c.push('mobile-side-menu__active')
      }
      return c
    },
  },

  watch: {
    collapsible (v) {
      if (v) {
        this.collapsed = true
      } else {
        this.collapsed = false
      }
    },

    collapsed (v) {
      if (v) {
        this.overflow = false
        this.setSubmenu(null)
      } else {
        this.timer = window.setTimeout(() => { this.overflow = true }, 1000)
      }
    },
  },

  mounted () {
    window.addEventListener('resize', this.resizeListener)
  },

  beforeUnmount () {
    clearTimeout(this.timer)
    window.removeEventListener('resize', this.resizeListener)
  },

  methods: {
    hasSubCategories (group) {
      return Object.keys(group.categories).length > 1
    },

    resizeListener () {
      if (window.innerWidth > 768) {
        this.menuStore.closeSideMenuActive()
      }
    },

    icon (icon) {
      if (icon === 'calendar') {
        return faCalendar
      }

      const iconClass = 'fa' + icon.split('-').map(s => s.charAt(0).toUpperCase() + s.slice(1)).join('')

      return icons[iconClass] || icon
    },

    setSubmenu (submenu) {
      this.activeSubmenu = submenu
    },
  },
})
</script>
